<mat-sidenav-container class="d-block h-full">
    <mat-sidenav
        [opened]="true"
        [mode]="'side'"
        [fixedInViewport]="true"
        class="w-[90px] shadow-sidebar"
    >
        <app-menu-side-nav [navLinkOptions]="navLinkOptions"></app-menu-side-nav>
    </mat-sidenav>
    <mat-sidenav-content class="!flex !bg-offwhite3">
        <div
            class="flex min-h-0 grow flex-col"
            [ngClass]="{ 'min-w-0': router.url !== '/' + RouteSegment.CompareTables }"
        >
            <app-header></app-header>
            <router-outlet></router-outlet>

            <ng-container *ngLet="store.select(selectTableProjectIds) | async as tableProjectIds">
                <ng-container
                    *ngIf="
                        tableProjectIds &&
                        router.url !== '/' + RouteSegment.CompareTables &&
                        !linkIsAdmin &&
                        !linkIsProjectCreation
                    "
                >
                    <div
                        *ngIf="tableProjectIds.length > 0"
                        class="linear-gradient-primary mx-[30px] mb-4 flex items-center justify-between rounded-md"
                    >
                        <div class="flex items-center justify-between gap-4 p-5 text-lg text-white">
                            <div>
                                {{
                                    tableProjectIds.length +
                                        ('projects.table.menu.selected-four-element' | transloco)
                                }}
                            </div>
                            <a
                                (click)="store.dispatch(deleteSelectedProjectIdsFromCompare())"
                                class="cursor-pointer rounded-full border border-white py-3 px-4 text-lg text-white"
                            >
                                {{ 'projects.table.menu.delete-all-selected-element' | transloco }}
                            </a>
                        </div>

                        <div class="p-5 text-lg text-white">
                            <a
                                [routerLink]="['', RouteSegment.CompareTables]"
                                class="flex items-center gap-2"
                            >
                                {{ 'projects.table.menu.compare' | transloco }}
                                <mat-icon svgIcon="arrow" class="!w-5 !rotate-[270deg]"></mat-icon>
                            </a>
                        </div>
                    </div>
                </ng-container>
                <div class="pr-[30px] text-right">© {{ 'projects.rights-text' | transloco }}</div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
