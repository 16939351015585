/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateSupplierContactLogDto } from '../models/create-supplier-contact-log-dto';
import { SupplierEntity } from '../models/supplier-entity';

@Injectable({
    providedIn: 'root'
})
export class SupplierApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation getSupplierContact
     */
    static readonly GetSupplierContactPath = '/supplier/contact-log/new';

    /**
     * Returns a supplier contact data.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSupplierContact()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getSupplierContact$Response(params: {
        context?: HttpContext;
        body: CreateSupplierContactLogDto;
    }): Observable<StrictHttpResponse<SupplierEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            SupplierApiService.GetSupplierContactPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<SupplierEntity>;
                })
            );
    }

    /**
     * Returns a supplier contact data.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSupplierContact$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getSupplierContact(params: {
        context?: HttpContext;
        body: CreateSupplierContactLogDto;
    }): Observable<SupplierEntity> {
        return this.getSupplierContact$Response(params).pipe(
            map((r: StrictHttpResponse<SupplierEntity>) => r.body as SupplierEntity)
        );
    }
}
