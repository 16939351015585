import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FilterProjectPhasesDto, PaginationRequest, ProjectApiService } from 'app/api';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as ProjectActions from './projects.actions';

@Injectable()
export class ProjectsEffects {
    constructor(
        private actions$: Actions,
        private projectApiService: ProjectApiService,
        private readonly router: Router
    ) {}

    project$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.projectsData),
            switchMap(() => {
                const body: PaginationRequest = {
                    page: 0,
                    perPage: 50
                };
                return this.projectApiService.filterProjects({ body }).pipe(
                    map((projectData) => {
                        return ProjectActions.projectsDataSuccess({ data: projectData.data! });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            ProjectActions.projectsDataFailure({ error: error.error.message })
                        );
                    })
                );
            })
        )
    );
    createProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.addProjectFileName),
            switchMap(({ projectName, projectAddress, projectBuildingFunction }) => {
                return this.projectApiService
                    .createProject({
                        body: {
                            name: projectName,
                            location: projectAddress!,
                            buildingFunction: projectBuildingFunction!
                        }
                    })
                    .pipe(
                        map((project) => {
                            return ProjectActions.selectProjectFile({ projectFile: project! });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                ProjectActions.addProjectFileFailure({ error: error.error.message })
                            );
                        })
                    );
            })
        )
    );

    public selectProjectFileNavigate$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ProjectActions.selectProjectFile),
                tap(() => {
                    this.router.navigate(['/', RouteSegment.Dashboard, RouteSegment.DraftPhase]);
                })
            );
        },
        {
            dispatch: false
        }
    );

    draftPhase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.draftPhaseData),
            switchMap(({ projectId, phase }) => {
                const body: FilterProjectPhasesDto = {
                    filter: phase,
                    page: 0,
                    perPage: 50
                };
                return this.projectApiService.filterProjectPhases({ id: projectId, body }).pipe(
                    map((projectData) => {
                        return ProjectActions.draftPhaseDataSuccess({
                            draftPhase: projectData.data!
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            ProjectActions.draftPhaseDataFailure({ error: error.error.message })
                        );
                    })
                );
            })
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.logout),
            map(() => ProjectActions.clearProjects())
        )
    );
}
