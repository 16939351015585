import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AccountType } from 'app/api';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { setActiveLanguageCode } from 'app/store/language/language.actions';
import {
    selectActiveLanguageCode,
    selectAvailableLanguages
} from 'app/store/language/language.selectors';
import { selectUrl } from 'app/store/router/router.selector';
import { logout } from 'app/store/user/user.actions';
import * as UserSelectors from 'app/store/user/user.selectors';
import { UserState } from 'app/store/user/user.state';
import dayjs from 'dayjs';
import { Observable, Subject, map } from 'rxjs';

export interface linkTreeInterface {
    translations$: Observable<string>;
    route: string;
}
@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
    @Input() public userType!: string;

    public RouteSegment = RouteSegment;
    public AccountType = AccountType;

    public userAccountTypeIsUnlimited: boolean = false;
    public userAccountTypeIsPremium: boolean = false;
    public userAccountTypeIsTrial: boolean = true;
    public userAccountValidToDate!: string;

    public languageSelectOpenState = false;
    public menuOpen = false;

    public selectedLanguageTitleEnglish$!: Observable<string>;
    public selectedLanguageTitleHungarian$!: Observable<string>;

    public changingValue: Subject<boolean> = new Subject();

    public setActiveLanguageCode = setActiveLanguageCode;
    public selectUserState = UserSelectors.selectUserState;
    public selectAvailableLanguages = selectAvailableLanguages;
    public activeLanguageCode$ = this.store.select(selectActiveLanguageCode);
    public linkTree$ = this.store.select(selectUrl).pipe(
        map((url) => {
            let link: linkTreeInterface[] = [];
            if (url.includes(RouteSegment.DraftPhaseCreation)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    },
                    {
                        translations$: this.translocoService.selectTranslate('menu.phases'),
                        route: `/${RouteSegment.Dashboard}/${RouteSegment.DraftPhase}`
                    },
                    {
                        translations$: this.translocoService.selectTranslate('menu.new-phases'),
                        route: `./${RouteSegment.DraftPhaseCreation}/${RouteSegment.Questions}`
                    }
                ];
            } else if (url.includes(RouteSegment.DraftPhase)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    },
                    {
                        translations$: this.translocoService.selectTranslate('menu.phases'),
                        route: `/${RouteSegment.Dashboard}/${RouteSegment.DraftPhase}`
                    }
                ];
            } else if (url.includes(RouteSegment.Dashboard)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    }
                ];
            } else if (url.includes(RouteSegment.MapView)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.map-view'),
                        route: `/${RouteSegment.MapView}`
                    }
                ];
            } else if (url.includes(RouteSegment.SalesManagement)) {
                link = [
                    {
                        translations$:
                            this.translocoService.selectTranslate('menu.sales-management'),
                        route: `/${RouteSegment.SalesManagement}`
                    }
                ];
            } else if (url.includes(RouteSegment.UserManagement)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.analytics'),
                        route: `/${RouteSegment.UserManagement}`
                    }
                ];
            } else if (url.includes(RouteSegment.ItemManagement)) {
                link = [
                    {
                        translations$:
                            this.translocoService.selectTranslate('menu.item-management'),
                        route: `/${RouteSegment.ItemManagement}`
                    }
                ];
            } else if (url.includes(RouteSegment.Profile)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.profile'),
                        route: `/${RouteSegment.Profile}`
                    }
                ];
            } else if (url.includes(RouteSegment.CompareTables)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    },
                    {
                        translations$: this.translocoService.selectTranslate(
                            'projects.table.menu.compare'
                        ),
                        route: `/${RouteSegment.CompareTables}`
                    }
                ];
            } else if (url.includes(RouteSegment.ContactPage)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate(
                            'contact-list.name-list-link'
                        ),
                        route: `/${RouteSegment.ContactPage}`
                    }
                ];
            }
            /*  else {
              return getDefaultTitle()
            } */

            return link;
        })
    );
    constructor(
        private readonly translocoService: TranslocoService,
        public readonly store: Store<UserState>,
        private readonly cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.store
            .select(UserSelectors.selectUserState)
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                /* TODO too many varibles */
                if (user.accountType === AccountType.Unlimited) {
                    this.userAccountTypeIsUnlimited = true;
                }
                if (user.accountType === AccountType.Premium) {
                    this.userAccountTypeIsPremium = true;
                }
                if (user.accountType === AccountType.Trial) {
                    this.userAccountTypeIsTrial = false;
                }

                if (user.subscription[0] && user.subscription[0].validTo) {
                    this.userAccountValidToDate = dayjs(user.subscription[0].validTo).format(
                        'YYYY.MM.DD HH:mm'
                    );
                }

                this.cdr.markForCheck();
            });

        this.selectedLanguageTitleEnglish$ = this.translocoService.selectTranslate(
            'header.languages.english'
        );
        this.selectedLanguageTitleHungarian$ = this.translocoService.selectTranslate(
            'header.languages.hungarian'
        );
    }

    public onLogout(): void {
        this.store.dispatch(logout());
    }

    public onClickShowPopup() {
        this.changingValue.next(true);
    }
}
