<div
    class="relative flex min-h-[var(--header-height)] w-full items-stretch justify-between gap-4 bg-white px-[30px] lg:p-[10px]"
    *ngLet="{
        activeLanguageCode: activeLanguageCode$ | async,
        userState: store.select(selectUserState) | async
    } as ctx"
>
    <div class="self-center">
        <a
            *ngFor="let item of linkTree$ | async; let last = last"
            [routerLink]="item.route"
            [ngClass]="{ 'text-darkgray': last, 'text-gray5': !last }"
            [class.disabled]="last ? true : null"
            class="cursor-pointer text-md font-medium text-gray1"
        >
            {{ item.translations$ | async }}
            <span *ngIf="!last">/</span>
        </a>
    </div>
    <div class="flex">
        <div
            *ngIf="ctx!.userState!.accountType === AccountType.Trial"
            class="btn-primary mx-auto my-8 flex cursor-pointer items-center gap-2"
            (click)="onClickShowPopup()"
        >
            <p>{{ 'expire-feedback.upragde-to-premium' | transloco }}</p>
        </div>

        <app-payment-popup [showPopup]="changingValue" [initShowPopup]="false"></app-payment-popup>

        <button
            mat-button
            (menuOpened)="menuOpen = true"
            (menuClosed)="menuOpen = false"
            [matMenuTriggerFor]="menu"
            class="flex !h-auto w-[280px] cursor-pointer !justify-start"
        >
            <div class="flex w-[280px] items-center justify-between">
                <div class="flex items-center gap-4">
                    <div class="relative flex w-12 shrink-0">
                        <img src="assets/imgs/user-profile.webp" class="h-12 rounded-full" />
                        <mat-icon
                            *ngIf="ctx!.userState!.isAdmin"
                            class="absolute bottom-[-6px] right-0"
                            svgIcon="kid_star"
                        ></mat-icon>
                    </div>
                    <div class="text-left">
                        <p class="text-md capitalize">
                            {{ ctx!.userState!.firstName + ' ' + ctx!.userState!.lastName }}
                        </p>
                        <p class="flex items-center gap-4 text-brand1">
                            <ng-container *ngIf="!userAccountTypeIsUnlimited; else unLimitedUser">
                                {{
                                    ctx!.userState!.isAdmin || userAccountTypeIsPremium
                                        ? ('menu.is-premium-text' | transloco)
                                        : ('menu.is-not-premium-text' | transloco)
                                }}
                            </ng-container>
                            <ng-template #unLimitedUser>
                                {{ 'menu.is-unlimited-text' | transloco }}
                            </ng-template>
                            <span
                                *ngIf="!ctx!.userState!.isAdmin && !userAccountTypeIsUnlimited"
                                class="h-[8px] w-[8px] rounded-full bg-red"
                                [matTooltip]="
                                    ('menu.end-of-subscription' | transloco) +
                                    userAccountValidToDate
                                "
                            >
                            </span>
                        </p>
                    </div>
                </div>
                <mat-icon
                    svgIcon="arrow"
                    [ngClass]="{ open: menuOpen }"
                    class="arrow mr-[14px] !w-3"
                ></mat-icon>
            </div>
        </button>
        <mat-menu #menu="matMenu" yPosition="below" class="menu w-[280px] !rounded-sm !p-3">
            <a
                [routerLink]="['', RouteSegment.Profile]"
                mat-menu-item
                class="!mb-3 !min-h-[32px] !px-0"
            >
                <div class="flex flex-col">
                    <div class="flex gap-4">
                        <img src="assets/imgs/user-profile.webp" class="h-12 w-12 rounded-full" />
                        <div class="flex flex-col">
                            <p class="text-darkgray">
                                {{ ctx!.userState!.firstName + ' ' + ctx!.userState!.lastName }}
                            </p>

                            <p class="text-gray1">{{ ctx!.userState!.email }}</p>
                        </div>
                    </div>
                </div>
            </a>

            <a
                [routerLink]="['', RouteSegment.Profile]"
                mat-menu-item
                class="!mb-3 !min-h-[32px] !gap-4 !px-2"
            >
                <mat-icon svgIcon="profile"></mat-icon>
                {{ 'menu.profile' | transloco }}
            </a>
            <a
                *ngIf="userAccountTypeIsTrial"
                [routerLink]="['', RouteSegment.ContactPage, RouteSegment.ModifyContact]"
                mat-menu-item
                class="!mb-3 !min-h-[32px] !gap-4 !px-2"
            >
                <mat-icon svgIcon="profile"></mat-icon>
                {{ 'contact-list.name-list-modify' | transloco }}
            </a>

            <mat-accordion (click)="$event.stopPropagation()">
                <mat-expansion-panel
                    (opened)="languageSelectOpenState = true"
                    (closed)="languageSelectOpenState = false"
                >
                    <mat-expansion-panel-header class="!mb-3 !h-[32px] !px-2">
                        <mat-panel-title class="gap-4">
                            <mat-icon svgIcon="selected_language"></mat-icon>
                            {{
                                ctx!.activeLanguageCode! === 'en'
                                    ? (selectedLanguageTitleEnglish$ | async)
                                    : (selectedLanguageTitleHungarian$ | async)
                            }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div
                        *ngFor="let lang of store.select(selectAvailableLanguages) | async"
                        [ngClass]="{
                            option: true,
                            optionIsActive: ctx!.activeLanguageCode === lang
                        }"
                        class="mb-3 flex h-[32px] items-center gap-4 px-2"
                        (click)="store.dispatch(setActiveLanguageCode({ languageCode: lang }))"
                    >
                        <img
                            [src]="'assets/imgs/icons/languages/' + lang + '.svg'"
                            class="mr-[2px] h-[22px] w-[22px] rounded-full"
                        />
                        {{
                            lang === 'en'
                                ? (selectedLanguageTitleEnglish$ | async)
                                : (selectedLanguageTitleHungarian$ | async)
                        }}
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <a (click)="onLogout()" mat-menu-item class="!min-h-[32px] !gap-4 !px-2">
                <mat-icon svgIcon="logout"></mat-icon>
                {{ 'menu.logout' | transloco }}</a
            >
        </mat-menu>
    </div>
</div>
