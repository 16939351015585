import { createReducer, on } from '@ngrx/store';
import {
    clearProjects,
    draftPhaseDataSuccess,
    projectsData,
    projectsDataFailure,
    projectsDataSuccess,
    selectProjectFile
} from './projects.actions';
import { ProjectsState } from './projects.state';

export const initialState: ProjectsState = {
    projectData: null,
    projectError: null,
    projectLoading: false,
    selectedProject: null,
    draftPhaseData: null
};

export const projectsReducer = createReducer(
    initialState,
    on(projectsData, (state) => ({
        ...state,
        projectLoading: true,
        projectError: null
    })),
    on(projectsDataSuccess, (state, { data }) => ({
        ...state,
        projectData: data,
        projectLoading: false
    })),
    on(projectsDataFailure, (state, { error }) => ({
        ...state,
        projectError: error,
        projectLoading: false
    })),
    on(selectProjectFile, (state, { projectFile }) => ({
        ...state,
        selectedProject: projectFile
    })),
    on(draftPhaseDataSuccess, (state, { draftPhase }) => ({
        ...state,
        draftPhaseData: draftPhase
    })),
    on(clearProjects, (state) => ({
        ...initialState
    }))
);
