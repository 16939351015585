import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { INavLinkOption } from 'app/models/ui/nav-link-option.model';
import { ProjectsState } from 'app/store/projects/projects.state';
import { selectUrl } from 'app/store/router/router.selector';
import { deleteSelectedProjectIdsFromCompare } from 'app/store/tables-compare/tables-compare.actions';
import { selectTableProjectIds } from 'app/store/tables-compare/tables-compare.selectors';
import { map } from 'rxjs';
@UntilDestroy()
@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthLayoutComponent implements OnInit {
    public navLinkOptions!: INavLinkOption[];
    public RouteSegment = RouteSegment;
    public selectTableProjectIds = selectTableProjectIds;
    public deleteSelectedProjectIdsFromCompare = deleteSelectedProjectIdsFromCompare;
    public linkIsAdmin: boolean = false;
    public linkIsProjectCreation: boolean = false;

    public linkTree$ = this.store.select(selectUrl).pipe(
        map((url) => {
            (this.linkIsAdmin = url.includes(RouteSegment.Admin)),
                (this.linkIsProjectCreation =
                    url.includes(RouteSegment.TrialDraftPhaseCreation) ||
                    url.includes(RouteSegment.DraftPhaseCreation));
        })
    );

    constructor(
        private readonly translocoService: TranslocoService,
        public readonly router: Router,
        public readonly store: Store<ProjectsState>,
        public readonly route: ActivatedRoute,
        protected readonly cdr: ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.linkTree$.pipe(untilDestroyed(this)).subscribe();

        this.navLinkOptions = [
            {
                text$: this.translocoService.selectTranslate('menu.user-management'),
                link: `/${RouteSegment.Admin}/${RouteSegment.UserManagement}`,
                icon: 'users',
                userIsAdmin: true
            },
            {
                text$: this.translocoService.selectTranslate('menu.projects'),
                link: `/${RouteSegment.Dashboard}`,
                icon: 'projects'
            },
            {
                text$: this.translocoService.selectTranslate('menu.map-view'),
                link: `/${RouteSegment.MapView}`,
                icon: 'map'
            },
            {
                text$: this.translocoService.selectTranslate('contact-list.name-list-link'),
                link: `/${RouteSegment.ContactPage}`,
                icon: 'contact_list'
            },
            {
                text$: this.translocoService.selectTranslate('menu.sales-management'),
                link: `/${RouteSegment.Admin}/${RouteSegment.SalesManagement}`,
                icon: 'sales',
                userIsAdmin: true
            },
            {
                text$: this.translocoService.selectTranslate('menu.item-management'),
                link: `/${RouteSegment.Admin}/${RouteSegment.ItemManagement}`,
                icon: 'itemmanagement',
                userIsAdmin: true
            },
            {
                text$: this.translocoService.selectTranslate('menu.contact-us'),
                link: RouteSegment.ContactUsPopup,
                icon: 'green-email',
                userIsAdmin: true
            }
        ];
    }
}
